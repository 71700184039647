import styled from "styled-components";

export const HeaderStyled = styled.header`
  font-weight: bold;
  text-align: center;
  font-size: 1rem;
  color: white;
  background-color: ${(props) =>
    props.isEdit === "edit" ? "#575757" : "gray"};
  padding: 6px;
  border-bottom: 1px solid white;
  :hover {
    background-color: darkgray;
  }
`;
