import React, { useState, useEffect } from "react";

//Components
import Button from "../../Button";

// Styles
import Header from "../../Header";
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowConsideracoes = ({ consideracoes, setConsideracoes, isInEnglish }) => {
    const [isEditing, setIsEditing] = useState();
    const [consideracao, setConsideracao] = useState({});
    const [showFormEdit, setShowFormEdit] = useState(false);

    const deleteHandler = (key) => {
        const remainingExp = consideracoes.filter((exp) => exp.id !== key);

        setConsideracoes(remainingExp);
    };

    const submitEditHandler = (e) => {
        e.preventDefault();
        const temp = [...consideracoes];
        temp[isEditing] = consideracao;
        setConsideracoes(temp);
        setIsEditing();
    };

    useEffect(() => {
        if (consideracoes && consideracoes[isEditing]) {
            setConsideracao(consideracoes[isEditing]);
        }
    }, [isEditing, consideracoes]);

    const moveUpHandler = (index) => {
        if (index === 0) {
            return;
        } else {
            const expsTemp = [...consideracoes];
            const temp = expsTemp[index];
            expsTemp[index] = expsTemp[index - 1];
            expsTemp[index - 1] = temp;

            setConsideracoes(expsTemp);
        }
    };
    const moveDownHandler = (index) => {
        if (index === consideracoes.length - 1) {
            return;
        } else {
            const expsTemp = [...consideracoes];
            const temp = expsTemp[index];
            expsTemp[index] = expsTemp[index + 1];
            expsTemp[index + 1] = temp;
            setConsideracoes(expsTemp);
        }
    };

    const showEditHandler = () => {
        setShowFormEdit(!showFormEdit);
    };

    return (
        <div>
            {(consideracoes && consideracoes.length > 0) && (
                <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
                    <Header isEdit={"edit"}>
                        {isInEnglish ? "PREMIERSOFT CONSIDERATIONS - EDIT" : "CONSIDERAÇÕES PREMIERSOFT - EDITAR"}
                    </Header>
                </div>
            )}
            {showFormEdit && (
                <>
                    {(consideracoes && consideracoes.length > 0) &&
                        consideracoes.map((exp, index) =>
                            isEditing === index ? (
                                <FormField key={`cons ${index}`}>
                                    <form key={exp.id} onSubmit={submitEditHandler}>
                                        <label>{isInEnglish ? "Description:" : "Descrição:"}</label>
                                        <textarea
                                            defaultValue={exp.descricao}
                                            onChange={(e) => {
                                                setConsideracao({
                                                    ...consideracao,
                                                    descricao: e.target.value,
                                                });
                                            }}
                                        ></textarea>
                                        <Button type="submit">CONFIRM</Button>
                                    </form>
                                </FormField>
                            ) : (
                                <PreviewStyled>
                                    <div key={exp.id} className="previewExp">
                                        {!exp.emptyLine && !exp.repeatTitle && (
                                            <>
                                                <div>
                                                    <b>{isInEnglish ? "Description:" : "Descrição:"} </b>
                                                    {exp.descricao}
                                                </div>
                                                <div className="buttons">
                                                    <button onClick={() => moveUpHandler(index)}>
                                                        UP
                                                    </button>
                                                    <button onClick={() => moveDownHandler(index)}>
                                                        DOWN
                                                    </button>
                                                    <Button onClick={() => setIsEditing(index)}>
                                                        EDIT
                                                    </Button>
                                                    <Button onClick={() => deleteHandler(exp.id)}>
                                                        DELETE
                                                    </Button>
                                                </div>
                                                <hr />
                                            </>
                                        )}
                                        {exp.emptyLine === true && (
                                            <div style={{ textAlign: "center" }}>
                                                {`(${exp.emptyLines}x)--------------------------------`}
                                                <Button onClick={() => moveUpHandler(index)}>UP</Button>
                                                <Button onClick={() => moveDownHandler(index)}>
                                                    DOWN
                                                </Button>
                                                <Button onClick={() => deleteHandler(exp.id)}>
                                                    DELETE
                                                </Button>
                                            </div>
                                        )}
                                        {exp.repeatTitle === true && (
                                            <div style={{ textAlign: "center" }}>
                                                ------------TITLE------------
                                                <Button onClick={() => moveUpHandler(index)}>UP</Button>
                                                <Button onClick={() => moveDownHandler(index)}>
                                                    DOWN
                                                </Button>
                                                <Button onClick={() => deleteHandler(exp.id)}>
                                                    DELETE
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </PreviewStyled>
                            )
                        )}
                </>
            )}
        </div>
    );
};

export default ShowConsideracoes;
