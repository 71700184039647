import React from "react";
import ReactDOM from "react-dom";
import Main from "./pages/Main";

function App() {
  return (
    <div className="App" style={{ margin: 0, padding: 0 }}>
      <Main></Main>
    </div>
  );
}
ReactDOM.render(<App />, document.getElementById("root"));

export default App;
