import styled from "styled-components";

export const FormFieldPreview = styled.div`
  div {
    max-width: 100%;
  }

  .buttons {
    text-align: center;
    padding: 8px;
  }

  button {
    margin: 5px;
    padding: 4px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    background-color: white;
    box-shadow: 1px 1px 2px grey;
  }
  button:hover {
    background-color: #f0f0f0;
  }
`;
