import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingBottom: 130,
    paddingHorizontal: 0,
    position: "relative;",
  },
  title: {
    fontSize: 16,
    fontFamily: "Montserrat",
    fontStyle: "bolder",
    marginTop: "40px",
  },

  titleRight: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
    fontSize: 13,
    fontFamily: "Montserrat",
    fontStyle: "bolder",
  },
  cargoRight: {
    fontSize: 11,
    fontFamily: "Montserrat",
    fontStyle: "bolder",
  },
  interesse: {
    marginBottom: 15,
  },
  text: {
    fontSize: 11,
    textAlign: "justify",
    fontFamily: "Montserrat",
    marginTop: 3,
  },
  Exps: {
    // marginBottom: "40px",
  },
  Cons: {
    marginTop: "15px",
    padding: '5px 10px 10px',
    borderRadius: '8px',
    backgroundColor: '#E8EBF9'
  },
  Exp: {
    marginTop: "15px",
  },
  experience: {
    fontSize: 14,
    fontFamily: "Montserrat",
    fontStyle: "bolder",
  },
  cargo: {
    fontSize: 12,
    fontFamily: "Montserrat",
    fontStyle: "bold",
  },
  description: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Montserrat",
    marginTop: 2,
  },
  complemento: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Montserrat",
    marginBottom: 3,
  },
  descriptionHab: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Montserrat",
  },
  circles: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3px",
  },
  circle: {
    width: "10px",
    height: "10px",
    margin: "2px",
  },
  halfCircleSVG: {
    transform: "rotate(90deg) scale(0.9)",
  },
  check: {
    width: "12px",
    height: "12px",
    position: "relative",
    left: 4,
    bottom: 2,
  },
  semNivel: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    // marginBottom: "15px",
  },
  semNivelContainer: {
    marginBottom: "15px",
  },
  iconExp: {
    width: "50px",
    top: "-50px",
    left: "-20px",
    position: "absolute",
  },
  iconExpRepeat: {
    width: "50px",
    top: "0",
    left: "-20px",
    position: "absolute",
  },
  iconForm: {
    width: "65px",
    top: "-60px",
    left: "-20px",
    position: "absolute",
  },
  iconFormRepeat: {
    width: "65px",
    top: "0",
    left: "-20px",
    position: "absolute",
  },
  iconCur: {
    width: "60px",
    top: "-60px",
    left: "-20px",
    position: "absolute",
  },
  iconCurRepeat: {
    width: "60px",
    top: "0",
    left: "-20px",
    position: "absolute",
  },
  iconCert: {
    width: "73px",
    top: "-50px",
    left: "-30px",
    position: "absolute",
  },
  iconCertRepeat: {
    width: "73px",
    top: "10",
    left: "-30px",
    position: "absolute",
  },
  header: {
    marginBottom: 40,
    // border: "3px solid blue",
  },
  headerImg: {
    zIndex: 1,
  },
  headerFuncao: {
    position: "absolute",
    marginLeft: 45,
    left: "0",
    top: "30%",
    fontSize: 24,
    fontFamily: "Montserrat",
    fontStyle: "bolder",
    color: "white",
  },
  headerNome: {
    position: "absolute",
    marginLeft: 45,
    left: "0",
    top: 60,
    fontSize: 18,
    fontFamily: "Montserrat",
    color: "white",
  },
  footerUm: {
    marginLeft: 45,
    position: "absolute",
    bottom: 25,
    fontFamily: "Montserrat",
    fontSize: 10,
  },
  footerDois: {
    marginLeft: 45,
    position: "absolute",
    bottom: 14,
    fontFamily: "Montserrat",
    fontSize: 10,
  },
  footerPremier: {
    fontFamily: "Montserrat",
    fontSize: 10,
    fontStyle: "bolder",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  bodyCenter: {
    // top: "-40px",
    display: "flex",
    flexDirection: "row",
  },
  bodyLeft: {
    width: "60%",
    marginLeft: 45,
    marginRight: 40,
  },
  bodyRight: {
    marginTop: "40px",

    width: "35%",
    marginLeft: "18px",
  },
  line: {
    position: "absolute",
    right: 0,
    width: 215,
    height: "66vh",
    marginTop: 140,
    borderLeft: "1px solid #00253f",
  },
  logo: {
    position: "absolute",
    width: 120,
    left: 240,
    bottom: 60,
  },
  footer: {
    height: 50,
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
  footerRight: {
    fontFamily: "Montserrat",
    fontStyle: "bolder",
    position: "absolute",
    right: 30,
    fontSize: 9,
    bottom: 15,
  },
  dateRange: {
    fontFamily: "Montserrat",
    fontSize: 10,
    marginTop: "-5px",
    marginBottom: 8,
  },
});
