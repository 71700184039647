import React, { useState, useEffect } from "react";

//Components
import Button from "../../Button";

// Styles
import Header from "../../Header";
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowCertificados = ({ certificados, setCertificados, isInEnglish }) => {
  const [isEditing, setIsEditing] = useState();
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [certificado, setCertificado] = useState();

  const deleteHandler = (key) => {
    const remainingCur = certificados.filter((cert) => cert.id !== key);

    setCertificados(remainingCur);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();
    const temp = [...certificados];

    temp[isEditing] = certificado;
    setCertificados(temp);
    setIsEditing();
  };

  useEffect(() => {
    if (certificados[isEditing]) {
      setCertificado(certificados[isEditing]);
    }
  }, [isEditing, certificados]);

  const moveUpHandler = (index) => {
    if (index === 0) {
      return;
    } else {
      const certTemp = [...certificados];
      const temp = certTemp[index];
      certTemp[index] = certTemp[index - 1];
      certTemp[index - 1] = temp;
      setCertificados(certTemp);
    }
  };
  const moveDownHandler = (index) => {
    if (index === certificados.length - 1) {
      return;
    } else {
      const certTemp = [...certificados];
      const temp = certTemp[index];
      certTemp[index] = certTemp[index + 1];
      certTemp[index + 1] = temp;
      setCertificados(certTemp);
    }
  };
  const showEditHandler = () => {
    setShowFormEdit(!showFormEdit);
  };

  return (
    <div>
      <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
        {certificados.length > 0 && (
          <Header isEdit={"edit"}>
            {isInEnglish ? "CERTIFICATES - EDIT" : "CERTIFICADOS - EDITAR"}
          </Header>
        )}
      </div>
      {showFormEdit && (
        <>
          {certificados.length > 0 &&
            certificados.map((cert, index) =>
              isEditing === index ? (
                <FormField key={`cert ${index}`}>
                  <form key={cert.id} onSubmit={submitEditHandler}>
                    <label>{isInEnglish ? "Course:" : "Curso:"}</label>
                    <input
                      type="text"
                      defaultValue={cert.nome}
                      onChange={(e) => {
                        setCertificado({
                          ...certificado,
                          nome: e.target.value,
                        });
                      }}
                    ></input>
                    <label>
                      {isInEnglish ? "Institution:" : "Instituição:"}
                    </label>
                    <input
                      type="text"
                      defaultValue={cert.instituicao}
                      onChange={(e) => {
                        setCertificado({
                          ...certificado,
                          instituicao: e.target.value,
                        });
                      }}
                    ></input>
                    <label>{isInEnglish ? "Finished in:" : "Conclusão:"}</label>
                    <input
                      type="text"
                      defaultValue={cert.conclusao}
                      onChange={(e) => {
                        setCertificado({
                          ...certificado,
                          conclusao: e.target.value,
                        });
                      }}
                    ></input>
                    <br />
                    <Button type="submit">CONFIRM</Button>
                  </form>
                </FormField>
              ) : (
                <PreviewStyled>
                  <div key={cert.id} className="previewExp">
                    {!cert.emptyLine && !cert.repeatTitle && (
                      <>
                        <div>
                          <b>{isInEnglish ? "Course:" : "Curso:"}</b>
                          {cert.nome}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Institution:" : "Instituição:"}</b>
                          {cert.instituicao}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Finished in:" : "Conclusão:"}</b>
                          {cert.conclusao}
                        </div>

                        <div className="buttons">
                          <button onClick={() => moveUpHandler(index)}>
                            UP
                          </button>
                          <button onClick={() => moveDownHandler(index)}>
                            DOWN
                          </button>
                          <Button onClick={() => setIsEditing(index)}>
                            EDIT
                          </Button>
                          <Button onClick={() => deleteHandler(cert.id)}>
                            DELETE
                          </Button>
                        </div>
                        <hr />
                      </>
                    )}
                    {cert.emptyLine === true && (
                      <div style={{ textAlign: "center" }}>
                        {`(${cert.emptyLines}x)--------------------------------`}
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(cert.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                    {cert.repeatTitle === true && (
                      <div style={{ textAlign: "center" }}>
                        ------------TITLE------------
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(cert.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                </PreviewStyled>
              )
            )}
        </>
      )}
    </div>
  );
};

export default ShowCertificados;
