import React, { useEffect, useState } from "react";

//Components
import Button from "../../Button";
import Header from "../../Header";

// Styles
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowHabilidades = ({ habilidades, setHabilidades, isInEnglish }) => {
  const [isEditing, setIsEditing] = useState();
  const [habilidade, setHabilidade] = useState();
  const [showFormEdit, setShowFormEdit] = useState(false);

  const deleteHandler = (key) => {
    const remainingInt = habilidades.filter((hab) => hab.id !== key);

    setHabilidades(remainingInt);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();
    const temp = [...habilidades];
    temp[isEditing] = habilidade;
    setHabilidades(temp);
    setIsEditing();
  };

  useEffect(() => {
    if (habilidades[isEditing]) {
      setHabilidade(habilidades[isEditing]);
    }
  }, [isEditing, habilidades]);

  const moveUpHandler = (index) => {
    if (index === 0) {
      return;
    } else {
      const habTemp = [...habilidades];
      const temp = habTemp[index];
      habTemp[index] = habTemp[index - 1];
      habTemp[index - 1] = temp;
      setHabilidades(habTemp);
    }
  };
  const moveDownHandler = (index) => {
    if (index === habilidades.length - 1) {
      return;
    } else {
      const habTemp = [...habilidades];
      const temp = habTemp[index];
      habTemp[index] = habTemp[index + 1];
      habTemp[index + 1] = temp;
      setHabilidades(habTemp);
    }
  };
  const showEditHandler = () => {
    setShowFormEdit(!showFormEdit);
  };
  return (
    <div>
      <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
        {habilidades.length > 0 && (
          <Header isEdit={"edit"}>
            {isInEnglish
              ? "COMPLEMENTARY SKILLS - EDIT"
              : "HABILIDADES COMPLEMENTARES - EDITAR"}
          </Header>
        )}
      </div>
      {showFormEdit && (
        <>
          {habilidades.length > 0 &&
            habilidades.map((hab, index) =>
              isEditing === index ? (
                <FormField key={`hab ${index}`}>
                  <form key={hab.id} onSubmit={submitEditHandler}>
                    <label>{isInEnglish ? "Skill:" : "Habilidade:"}</label>
                    <input
                      type="text"
                      defaultValue={hab.nome}
                      onChange={(e) => {
                        setHabilidade({ ...habilidade, nome: e.target.value });
                      }}
                    ></input>
                    <label>
                      {isInEnglish ? "Complement:" : "Complemento:"}
                    </label>
                    <input
                      type="text"
                      defaultValue={hab.complemento}
                      onChange={(e) => {
                        setHabilidade({
                          ...habilidade,
                          complemento: e.target.value,
                        });
                      }}
                    ></input>
                    {hab.level !== "" && (
                      <>
                        <label>
                          {isInEnglish ? "Skill Points:" : "Pontos:"}
                        </label>
                        <input
                          type="range"
                          min="0"
                          max="6"
                          step="0.5"
                          defaultValue={hab.level}
                          onChange={(e) =>
                            setHabilidade({
                              ...habilidade,
                              level: e.target.value,
                            })
                          }
                        ></input>
                        {habilidade ? <span>{habilidade.level}</span> : null}
                      </>
                    )}
                    <br />
                    <Button type="submit">CONFIRM</Button>
                  </form>
                </FormField>
              ) : (
                <PreviewStyled>
                  <div key={hab.id} className="previewExp">
                    {!hab.emptyLine && !hab.repeatTitle && (
                      <>
                        <div>
                          <b> {isInEnglish ? "Skill:" : "Habilidade:"}</b>
                          {hab.nome}
                        </div>
                        <div>
                          <b> {isInEnglish ? "Complement:" : "Complemento:"}</b>
                          {hab.complemento}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Skill Points:" : "Pontos:"}</b>
                          {hab.level}
                        </div>

                        <div className="buttons">
                          <button onClick={() => moveUpHandler(index)}>
                            UP
                          </button>
                          <button onClick={() => moveDownHandler(index)}>
                            DOWN
                          </button>

                          <Button onClick={() => setIsEditing(index)}>
                            EDIT
                          </Button>
                          <Button onClick={() => deleteHandler(hab.id)}>
                            DELETE
                          </Button>
                        </div>
                        <hr />
                      </>
                    )}
                    {hab.emptyLine === true && (
                      <div style={{ textAlign: "center" }}>
                        {`(${hab.emptyLines}x)--------------------------------`}
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(hab.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                    {hab.repeatTitle === true && (
                      <div style={{ textAlign: "center" }}>
                        ------------TITLE------------
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(hab.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                </PreviewStyled>
              )
            )}
        </>
      )}
    </div>
  );
};

export default ShowHabilidades;
