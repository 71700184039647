import React, { useState, useEffect } from "react";

//Components
import Button from "../../Button";

// Styles
import Header from "../../Header";
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowCursos = ({ cursos, setCursos, isInEnglish }) => {
  const [isEditing, setIsEditing] = useState();
  const [curso, setCurso] = useState();
  const [showFormEdit, setShowFormEdit] = useState(false);

  const deleteHandler = (key) => {
    const remainingCur = cursos.filter((cur) => cur.id !== key);

    setCursos(remainingCur);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();
    const temp = [...cursos];

    temp[isEditing] = curso;
    setCursos(temp);
    setIsEditing();
  };

  useEffect(() => {
    if (cursos[isEditing]) {
      setCurso(cursos[isEditing]);
    }
  }, [isEditing, cursos]);

  const moveUpHandler = (index) => {
    if (index === 0) {
      return;
    } else {
      const cursTemp = [...cursos];
      const temp = cursTemp[index];
      cursTemp[index] = cursTemp[index - 1];
      cursTemp[index - 1] = temp;
      setCursos(cursTemp);
    }
  };
  const moveDownHandler = (index) => {
    if (index === cursos.length - 1) {
      return;
    } else {
      const cursTemp = [...cursos];
      const temp = cursTemp[index];
      cursTemp[index] = cursTemp[index + 1];
      cursTemp[index + 1] = temp;
      setCursos(cursTemp);
    }
  };
  const showEditHandler = () => {
    setShowFormEdit(!showFormEdit);
  };

  return (
    <div>
      <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
        {cursos.length > 0 && (
          <Header isEdit={"edit"}>
            {isInEnglish ? "ADDITIONAL COURSES - EDIT" : "CURSOS - EDITAR"}
          </Header>
        )}
      </div>
      {showFormEdit && (
        <>
          {cursos.length > 0 &&
            cursos.map((cur, index) =>
              isEditing === index ? (
                <FormField key={`cur ${index}`}>
                  <form key={cur.id} onSubmit={submitEditHandler}>
                    <label>{isInEnglish ? "Course:" : "Curso:"}</label>
                    <input
                      type="text"
                      defaultValue={cur.nome}
                      onChange={(e) => {
                        setCurso({ ...curso, nome: e.target.value });
                      }}
                    ></input>
                    <label>
                      {isInEnglish ? "Institution:" : "Instituição:"}
                    </label>
                    <input
                      type="text"
                      defaultValue={cur.instituicao}
                      onChange={(e) => {
                        setCurso({ ...curso, instituicao: e.target.value });
                      }}
                    ></input>
                    <label>{isInEnglish ? "Finished in:" : "Conclusão:"}</label>
                    <input
                      type="text"
                      defaultValue={cur.conclusao}
                      onChange={(e) => {
                        setCurso({ ...curso, conclusao: e.target.value });
                      }}
                    ></input>
                    <br />

                    <Button type="submit">CONFIRM</Button>
                  </form>
                </FormField>
              ) : (
                <PreviewStyled>
                  <div key={cur.id} className="previewExp">
                    {!cur.emptyLine && !cur.repeatTitle && (
                      <>
                        <div>
                          <b>{isInEnglish ? "Course:" : "Curso:"}</b> {cur.nome}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Institution:" : "Instituição:"}</b>
                          {cur.instituicao}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Finished in:" : "Conclusão:"} </b>
                          {cur.conclusao}
                        </div>

                        <div className="buttons">
                          <button onClick={() => moveUpHandler(index)}>
                            UP
                          </button>
                          <button onClick={() => moveDownHandler(index)}>
                            DOWN
                          </button>
                          <Button onClick={() => setIsEditing(index)}>
                            EDIT
                          </Button>
                          <Button onClick={() => deleteHandler(cur.id)}>
                            DELETE
                          </Button>
                        </div>
                        <hr />
                      </>
                    )}
                    {cur.emptyLine === true && (
                      <div style={{ textAlign: "center" }}>
                        {`(${cur.emptyLines}x)--------------------------------`}
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(cur.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                    {cur.repeatTitle === true && (
                      <div style={{ textAlign: "center" }}>
                        ------------TITLE------------
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(cur.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                </PreviewStyled>
              )
            )}
        </>
      )}
    </div>
  );
};

export default ShowCursos;
