import React, { useState, useEffect } from "react";

//Components
import Button from "../../Button";

// Styles
import Header from "../../Header";
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowFormacoes = ({ formacoes, setFormacoes, isInEnglish }) => {
  const [isEditing, setIsEditing] = useState();
  const [formacao, setFormacao] = useState();
  const [showFormEdit, setShowFormEdit] = useState(false);

  const deleteHandler = (key) => {
    const remainingFormac = formacoes.filter((formac) => formac.id !== key);

    setFormacoes(remainingFormac);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();
    const temp = [...formacoes];
    temp[isEditing] = formacao;
    setFormacoes(temp);
    setIsEditing();
  };

  useEffect(() => {
    if (formacoes[isEditing]) {
      setFormacao(formacoes[isEditing]);
    }
  }, [isEditing, formacoes]);

  const moveUpHandler = (index) => {
    if (index === 0) {
      return;
    } else {
      const formsTemp = [...formacoes];
      const temp = formsTemp[index];
      formsTemp[index] = formsTemp[index - 1];
      formsTemp[index - 1] = temp;
      setFormacoes(formsTemp);
    }
  };
  const moveDownHandler = (index) => {
    if (index === formacoes.length - 1) {
      return;
    } else {
      const formsTemp = [...formacoes];
      const temp = formsTemp[index];
      formsTemp[index] = formsTemp[index + 1];
      formsTemp[index + 1] = temp;
      setFormacoes(formsTemp);
    }
  };
  const showEditHandler = () => {
    setShowFormEdit(!showFormEdit);
  };

  return (
    <div>
      {formacoes.length > 0 && (
        <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
          <Header isEdit={"edit"}>
            {isInEnglish ? "EDUCATION - EDIT" : "FORMAÇÃO ACADÊMICA - EDITAR"}
          </Header>
        </div>
      )}
      {showFormEdit && (
        <>
          {formacoes.length > 0 &&
            formacoes.map((formac, index) =>
              isEditing === index ? (
                <FormField key={`formac ${index}`}>
                  <form key={formac.id} onSubmit={submitEditHandler}>
                    <label>{isInEnglish ? "Course:" : "Curso:"}</label>
                    <input
                      type="text"
                      defaultValue={formac.nome}
                      onChange={(e) => {
                        setFormacao({ ...formacao, nome: e.target.value });
                      }}
                    ></input>
                    <label>
                      {isInEnglish ? "Institution:" : "Instituição:"}
                    </label>
                    <input
                      type="text"
                      defaultValue={formac.instituicao}
                      onChange={(e) => {
                        setFormacao({
                          ...formacao,
                          instituicao: e.target.value,
                        });
                      }}
                    ></input>
                    <label>
                      {isInEnglish ? "Course type:" : "Tipo de curso:"}
                    </label>
                    <input
                      type="text"
                      defaultValue={formac.tipo}
                      onChange={(e) => {
                        setFormacao({ ...formacao, tipo: e.target.value });
                      }}
                    ></input>
                    <label>{isInEnglish ? "Finished in:" : "Conclusão:"}</label>
                    <input
                      type="text"
                      defaultValue={formac.conclusao}
                      onChange={(e) => {
                        setFormacao({
                          ...formacao,
                          conclusao: e.target.value,
                        });
                      }}
                    ></input>
                    <br />
                    <Button type="submit">CONFIRM</Button>
                  </form>
                </FormField>
              ) : (
                <PreviewStyled>
                  <div key={formac.id} className="previewExp">
                    {!formac.emptyLine && !formac.repeatTitle && (
                      <>
                        <div>
                          <b>{isInEnglish ? "Course:" : "Curso:"}</b>
                          {formac.nome}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Institution:" : "Instituição:"}</b>
                          {formac.instituicao}
                        </div>
                        <div>
                          <b>
                            {isInEnglish ? "Course type:" : "Tipo de curso:"}
                          </b>
                          {formac.tipo}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Finished in:" : "Conclusão:"}</b>
                          {formac.conclusao}
                        </div>

                        <div className="buttons">
                          <button onClick={() => moveUpHandler(index)}>
                            UP
                          </button>
                          <button onClick={() => moveDownHandler(index)}>
                            DOWN
                          </button>
                          <Button onClick={() => setIsEditing(index)}>
                            EDIT
                          </Button>
                          <Button onClick={() => deleteHandler(formac.id)}>
                            DELETE
                          </Button>
                        </div>
                        <hr />
                      </>
                    )}
                    {formac.emptyLine === true && (
                      <div style={{ textAlign: "center" }}>
                        {`(${formac.emptyLines}x)--------------------------------`}
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(formac.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                    {formac.repeatTitle === true && (
                      <div style={{ textAlign: "center" }}>
                        ------------TITLE------------
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(formac.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                </PreviewStyled>
              )
            )}
        </>
      )}
    </div>
  );
};

export default ShowFormacoes;
