import React, { useEffect, useState } from "react";

// Import Functions
import { emptyLine } from "../../utils/emptyLine";
import { repeatTitle } from "../../utils/repeatTitle";

//Styles
import { FormField } from "../FormField";
import { Form, FormSectionStyle } from "../../pages/Main/styles";
import { FormFieldPreview } from "../FormFieldPreview";

// Components
import Button from "../Button";
import ShowInteresses from "./ShowInteresses";
import Header from "../Header";

const Interesses = ({ interesses, setInteresses, isInEnglish }) => {
  const [level, setLevel] = useState("0");
  const [nome, setInteresse] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [emptyLines, setEmptyLines] = useState(1);
  const [complemento, setComplemento] = useState("");

  const levelHandler = (value) => {
    setLevel(value);
  };
  const submitHandler = (e) => {
    e.preventDefault();

    const id = Math.floor(Math.random() * 10000) + 1;
    const novoInt = {
      id,
      nome,
      complemento,
      level,
    };

    setInteresses([...interesses, novoInt]);
    if (!disabled) {
      setLevel("0");
    }
    setInteresse("");
    setComplemento("");
    setEmptyLines(1);
  };

  const disableHandler = () => {
    setDisabled(!disabled);
  };

  useEffect(() => {
    if (disabled) {
      setLevel("");
    } else {
      setLevel(0);
    }
  }, [disabled]);

  useEffect(() => {
    setLevel(0);
    setDisabled(false);
  }, [showForm]);

  const emptyLineHandler = (e) => {
    e.preventDefault();

    const newLine = emptyLine(emptyLines);
    setInteresses([...interesses, newLine]);
    setEmptyLines(1);
  };
  const repeatTitleHandler = () => {
    const newLine = repeatTitle();
    setInteresses([...interesses, newLine]);
  };

  const showFormHandler = () => {
    setShowForm(!showForm);
    setEmptyLines(1);
  };

  return (
    <FormSectionStyle>
      <div onClick={showFormHandler} style={{ cursor: "pointer" }}>
        <Header>
          {isInEnglish ? "MAIN SKILLS" : "PRINCIPAIS HABILIDADES"}
        </Header>
      </div>
      {showForm && (
        <FormField>
          <Form>
            <label> {isInEnglish ? "Skill:" : "Habilidade:"} </label>
            <input
              type="text"
              value={nome}
              onChange={(e) => {
                setInteresse(e.target.value);
              }}
            ></input>
            <label> {isInEnglish ? "Complement:" : "Complemento:"} </label>
            <input
              type="text"
              value={complemento}
              onChange={(e) => {
                setComplemento(e.target.value);
              }}
            ></input>
            <label> {isInEnglish ? "Skill Points:" : "Pontos:"}</label>
            <input
              type="range"
              min="0"
              max="6"
              step="0.5"
              value={level}
              disabled={disabled}
              onChange={(e) => levelHandler(e.target.value)}
            ></input>
            <span>{level}</span>
            <div>
              <label className="check-box-label">
                {isInEnglish ? "No Points:" : "Sem Pontos:"}
                <input
                  type="checkbox"
                  className="check-box-button"
                  onChange={disableHandler}
                />
              </label>
            </div>
            <br />
            <Button onClick={submitHandler}>ADD</Button>
          </Form>
          <hr />
          <form onSubmit={emptyLineHandler}>
            <input
              type="number"
              min="1"
              max="30"
              value={emptyLines}
              onChange={(e) => {
                setEmptyLines(e.target.value);
              }}
              style={{ width: "40px" }}
            />
            <button type="submit">ADD EMPTY LINE</button>
          </form>
          <button onClick={repeatTitleHandler}>REPEAT TITLE</button>
        </FormField>
      )}
      <FormFieldPreview>
        <ShowInteresses
          isInEnglish={isInEnglish}
          interesses={interesses}
          setInteresses={setInteresses}
        />
      </FormFieldPreview>
    </FormSectionStyle>
  );
};

export default Interesses;
