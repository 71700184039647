import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import Cursos from "../../components/Cursos";
import Formacoes from "../../components/Formacoes";
import Candidato from "../../components/Candidato";
import Interesses from "../../components/Interesses";
import Habilidades from "../../components/Habilidades";
import Experiencias from "../../components/Experiencias";
import Certificados from "../../components/Certificados";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocumentInPortuguese } from "../../PDF_Template/InPortuguese";
import { MyDocumentInEnglish } from "../../PDF_Template/InEnglish";
import {PDFDocument} from "pdf-lib";

//Styles
import {MainStyled, Formulario, ContinueButton, ContinueButtonFile} from "./styles";
import Consideracoes from "../../components/Consideracoes";

const Main = () => {
  const [cursos, setCursos] = useState([]);
  const [userData, setUserData] = useState({});
  const [formacoes, setFormacoes] = useState([]);
  const [candidato, setCandidato] = useState({});
  const [interesses, setInteresses] = useState([]);
  const [habilidades, setHabilidades] = useState([]);
  const [experiencias, setExperiencias] = useState([]);
  const [certificados, setCertificados] = useState([]);
  const [consideracoes, setConsideracoes] = useState([]);
  const [isInEnglish, setIsInEnglish] = useState(false);

  const updateUserCandidate = () => {
    setUserData((prevState) => {
      return { ...prevState, candidato };
    });
  };
  useEffect(updateUserCandidate, [candidato]);

  const updateUserConsiderations = () => {
    setUserData((prevState) => {
      return { ...prevState, consideracoes };
    });
  };
  useEffect(updateUserConsiderations, [consideracoes]);

  const updateUserExperiences = () => {
    setUserData((prevState) => {
      return { ...prevState, experiencias };
    });
  };
  useEffect(updateUserExperiences, [experiencias]);

  const updateUserFormacoes = () => {
    setUserData((prevState) => {
      return { ...prevState, formacoes };
    });
  };
  useEffect(updateUserFormacoes, [formacoes]);

  const updateUserCursos = () => {
    setUserData((prevState) => {
      return { ...prevState, cursos };
    });
  };
  useEffect(updateUserCursos, [cursos]);

  const updateUserCertificados = () => {
    setUserData((prevState) => {
      return { ...prevState, certificados };
    });
  };
  useEffect(updateUserCertificados, [certificados]);

  const updateUserInteresses = () => {
    setUserData((prevState) => {
      return { ...prevState, interesses };
    });
  };
  useEffect(updateUserInteresses, [interesses]);

  const updateUserHabilidades = () => {
    setUserData((prevState) => {
      return { ...prevState, habilidades };
    });
  };
  useEffect(updateUserHabilidades, [habilidades]);

  const myDocument = isInEnglish ? (
    <MyDocumentInEnglish userData={userData} />
  ) : (
    <MyDocumentInPortuguese userData={userData} />
  );

  const nameCleared = () => {
    return (candidato.nome || "" ).toLowerCase().replace(/ /g, "-");
  }

  const importFile = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const data = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })

      const pdfDoc = await PDFDocument.load(data);
      const subject = pdfDoc.getSubject();
      if (subject) {
        const jsonData = new Buffer(subject, "base64").toString("utf-8");
        if (jsonData) {
          const parsed = JSON.parse(jsonData);
          if (parsed.candidato?.nome) {
            setUserData(parsed);
            setCursos(parsed.cursos);
            setFormacoes(parsed.formacoes);
            setCandidato(parsed.candidato);
            setInteresses(parsed.interesses);
            setHabilidades(parsed.habilidades);
            setExperiencias(parsed.experiencias);
            setCertificados(parsed.certificados);
            setConsideracoes(parsed.consideracoes ?? []);
          } else {
            alert("PDF não foi gerado pelo sistema.");
          }
        } else {
          alert("PDF não foi gerado pelo sistema.");
        }
      } else {
        alert("PDF não foi gerado pelo sistema.");
      }
    }
  }

  return (
    <MainStyled>
      <Formulario>
        <ContinueButton for="file-input">Continuar preenchendo</ContinueButton>
        <ContinueButtonFile  type="file" accept="application/pdf" onChange={importFile} id="file-input"/>
        <Candidato
          isInEnglish={isInEnglish}
          candidato={candidato}
          setCandidato={setCandidato}
        />
        <Consideracoes
          isInEnglish={isInEnglish}
          consideracoes={consideracoes}
          setConsideracoes={setConsideracoes}
        ></Consideracoes>
        <Experiencias
          isInEnglish={isInEnglish}
          experiencias={experiencias}
          setExperiencias={setExperiencias}
        />
        <Formacoes
          isInEnglish={isInEnglish}
          formacoes={formacoes}
          setFormacoes={setFormacoes}
        />
        <Cursos
          isInEnglish={isInEnglish}
          cursos={cursos}
          setCursos={setCursos}
        />
        <Certificados
          isInEnglish={isInEnglish}
          certificados={certificados}
          setCertificados={setCertificados}
        />

        <Interesses
          isInEnglish={isInEnglish}
          interesses={interesses}
          setInteresses={setInteresses}
        />
        <Habilidades
          isInEnglish={isInEnglish}
          habilidades={habilidades}
          setHabilidades={setHabilidades}
        />
      </Formulario>

      <div className="pdf-viewer-container">
        <PDFViewer className="pdf-viewer">{myDocument}</PDFViewer>
      </div>
      <PDFDownloadLink
        className="button-div"
        document={myDocument}
        fileName={`curriculo-${nameCleared()}.pdf`}
      >
        <div>DOWNLOAD</div>
      </PDFDownloadLink>
      <button onClick={() => setIsInEnglish(!isInEnglish)}>
        {`Change to: ${isInEnglish ? "Portuguese" : "English"}`}
      </button>
    </MainStyled>
  );
};
ReactDOM.render(<Main />, document.getElementById("root"));

export default Main;
