import styled from "styled-components";

export const FormField = styled.div`
  * {
    vertical-align: middle;
  }

  background-color: lightgray;
  text-align: center;
  flex-direction: column;
  align-items: center;
  min-width: 300px;

  label {
    display: block;
    padding: 5px;
    font-weight: bold;
  }

  textarea {
    display: block;
    width: 300px;
    height: 150px;
    margin: auto;
  }
  button {
    margin: 5px;
    padding: 4px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    background-color: white;
    box-shadow: 1px 1px 2px grey;
  }
  button:hover {
    background-color: #f0f0f0;
  }

  input {
    height: 25px;
    width: 300px;
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
    margin: auto;
  }

  .check-box-label {
    right: 0;
  }

  .check-box-button {
    width: 30px;
  }
`;
