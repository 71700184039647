import React, {useEffect, useState} from "react";
import Button from "../Button";

//Styles
import Header from "../Header";
import { Form, FormSectionStyle } from "../../pages/Main/styles";
import { FormField } from "../FormField";

const Candidato = ({ setCandidato, candidato, isInEnglish }) => {
  const [titulo, setTitulo] = useState( "");
  const [nome, setNome] = useState( "");
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if(candidato) {
      setTitulo(candidato.titulo);
      setNome(candidato.nome);
    }
  }, [candidato]);

  const submitHandler = (e) => {
    e.preventDefault();
    const candidatoInfo = {
      titulo,
      nome,
    };
    setCandidato(candidatoInfo);
  };
  const showFormHandler = () => {
    setShowForm(!showForm);
  };

  return (
    <FormSectionStyle>
      <div onClick={showFormHandler} style={{ cursor: "pointer" }}>
        <Header>{isInEnglish ? "CANDIDATE" : "CANDIDATO"}</Header>
      </div>
      {showForm && (
        <FormField>
          <Form onSubmit={submitHandler}>
            <div id="fields">
              <div>
                <label> {isInEnglish ? "Position:" : "Função:"} </label>
                <input
                  type="text"
                  value={titulo}
                  onChange={(e) => {
                    setTitulo(e.target.value);
                  }}
                ></input>
              </div>
              <div>
                <label> {isInEnglish ? "Name:" : "Nome:"} </label>
                <input
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                ></input>
              </div>
            </div>

            <Button type="submit">ADD</Button>
          </Form>
        </FormField>
      )}
    </FormSectionStyle>
  );
};

export default Candidato;
