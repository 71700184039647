import React, { useEffect, useState } from "react";

//Components
import Button from "../../Button";

// Styles
import Header from "../../Header";
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowInteresses = ({ interesses, setInteresses, isInEnglish }) => {
  const [isEditing, setIsEditing] = useState();
  const [interesse, setInteresse] = useState();
  const [showFormEdit, setShowFormEdit] = useState(false);

  const deleteHandler = (key) => {
    const remainingInt = interesses.filter((int) => int.id !== key);

    setInteresses(remainingInt);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();
    const temp = [...interesses];
    temp[isEditing] = interesse;
    setInteresses(temp);
    setIsEditing();
  };

  useEffect(() => {
    if (interesses[isEditing]) {
      setInteresse(interesses[isEditing]);
    }
  }, [isEditing, interesses]);

  const moveUpHandler = (index) => {
    if (index === 0) {
      return;
    } else {
      const intTemp = [...interesses];
      const temp = intTemp[index];
      intTemp[index] = intTemp[index - 1];
      intTemp[index - 1] = temp;
      setInteresses(intTemp);
    }
  };
  const moveDownHandler = (index) => {
    if (index === interesses.length - 1) {
      return;
    } else {
      const intTemp = [...interesses];
      const temp = intTemp[index];
      intTemp[index] = intTemp[index + 1];
      intTemp[index + 1] = temp;
      setInteresses(intTemp);
    }
  };
  const showEditHandler = () => {
    setShowFormEdit(!showFormEdit);
  };
  return (
    <div>
      <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
        {interesses.length > 0 && (
          <Header isEdit={"edit"}>
            {isInEnglish
              ? "MAIN SKILLS - EDIT"
              : "PRINCIPAIS HABILIDADES - EDITAR"}
          </Header>
        )}
      </div>
      {showFormEdit && (
        <>
          {interesses.length > 0 &&
            interesses.map((int, index) =>
              isEditing === index ? (
                <FormField key={`int ${index}`}>
                  <form key={int.id} onSubmit={submitEditHandler}>
                    <label>{isInEnglish ? "Skill:" : "Habilidade:"} </label>
                    <input
                      type="text"
                      defaultValue={int.nome}
                      onChange={(e) => {
                        setInteresse({ ...interesse, nome: e.target.value });
                      }}
                    ></input>
                    <label>
                      {isInEnglish ? "Complement:" : "Complemento:"}
                    </label>
                    <input
                      type="text"
                      defaultValue={int.complemento}
                      onChange={(e) => {
                        setInteresse({
                          ...interesse,
                          complemento: e.target.value,
                        });
                      }}
                    ></input>
                    {int.level !== "" && (
                      <>
                        <label>
                          {isInEnglish ? "Skill Points:" : "Pontos:"}
                        </label>
                        <input
                          type="range"
                          min="0"
                          max="6"
                          step="0.5"
                          defaultValue={int.level}
                          onChange={(e) =>
                            setInteresse({
                              ...interesse,
                              level: e.target.value,
                            })
                          }
                        ></input>
                        {interesse ? <span>{interesse.level}</span> : null}
                      </>
                    )}
                    <br />
                    <Button type="submit">CONFIRM</Button>
                  </form>
                </FormField>
              ) : (
                <PreviewStyled>
                  <div key={int.id} className="previewExp">
                    {!int.emptyLine && !int.repeatTitle && (
                      <>
                        <div>
                          <b>{isInEnglish ? "Skill:" : "Habilidade:"} </b>
                          {int.nome}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Complement:" : "Complemento:"}</b>
                          {int.complemento}
                        </div>

                        <div>
                          <b>{isInEnglish ? "Skill Points:" : "Pontos:"}</b>
                          {int.level}
                        </div>

                        <div className="buttons">
                          <button onClick={() => moveUpHandler(index)}>
                            UP
                          </button>
                          <button onClick={() => moveDownHandler(index)}>
                            DOWN
                          </button>

                          <Button onClick={() => setIsEditing(index)}>
                            EDIT
                          </Button>
                          <Button onClick={() => deleteHandler(int.id)}>
                            DELETE
                          </Button>
                        </div>
                        <hr />
                      </>
                    )}
                    {int.emptyLine === true && (
                      <div style={{ textAlign: "center" }}>
                        {`(${int.emptyLines}x)--------------------------------`}
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(int.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                    {int.repeatTitle === true && (
                      <div style={{ textAlign: "center" }}>
                        ------------TITLE------------
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(int.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                </PreviewStyled>
              )
            )}
        </>
      )}
    </div>
  );
};

export default ShowInteresses;
