import React, { useState, useEffect } from "react";

//Components
import Button from "../../Button";

// Styles
import Header from "../../Header";
import { FormField } from "../../FormField";
import { PreviewStyled } from "../../PreviewStyled";

const ShowExperiences = ({ experiencias, setExperiencias, isInEnglish }) => {
  const [isEditing, setIsEditing] = useState();
  const [experiencia, setExperiencia] = useState();
  const [showFormEdit, setShowFormEdit] = useState(false);

  const deleteHandler = (key) => {
    const remainingExp = experiencias.filter((exp) => exp.id !== key);

    setExperiencias(remainingExp);
  };

  const submitEditHandler = (e) => {
    e.preventDefault();
    const temp = [...experiencias];
    temp[isEditing] = experiencia;
    setExperiencias(temp);
    setIsEditing();
  };

  useEffect(() => {
    if (experiencias[isEditing]) {
      setExperiencia(experiencias[isEditing]);
    }
  }, [isEditing, experiencias]);

  const moveUpHandler = (index) => {
    if (index === 0) {
      return;
    } else {
      const expsTemp = [...experiencias];
      const temp = expsTemp[index];
      expsTemp[index] = expsTemp[index - 1];
      expsTemp[index - 1] = temp;

      setExperiencias(expsTemp);
    }
  };
  const moveDownHandler = (index) => {
    if (index === experiencias.length - 1) {
      return;
    } else {
      const expsTemp = [...experiencias];
      const temp = expsTemp[index];
      expsTemp[index] = expsTemp[index + 1];
      expsTemp[index + 1] = temp;
      setExperiencias(expsTemp);
    }
  };

  const showEditHandler = () => {
    setShowFormEdit(!showFormEdit);
  };

  return (
    <div>
      {experiencias.length > 0 && (
        <div onClick={showEditHandler} style={{ cursor: "pointer" }}>
          <Header isEdit={"edit"}>
            {isInEnglish ? "EXPERIENCES - EDIT" : "EXPERIÊNCIAS - EDITAR"}
          </Header>
        </div>
      )}
      {showFormEdit && (
        <>
          {experiencias.length > 0 &&
            experiencias.map((exp, index) =>
              isEditing === index ? (
                <FormField key={`exp ${index}`}>
                  <form key={exp.id} onSubmit={submitEditHandler}>
                    <label>{isInEnglish ? "Name:" : "Nome:"}</label>
                    <input
                      type="text"
                      defaultValue={exp.nome}
                      onChange={(e) => {
                        setExperiencia({
                          ...experiencia,
                          nome: e.target.value,
                        });
                      }}
                    ></input>
                    <label>{isInEnglish ? "Start Date:" : "Início:"}</label>
                    <input
                      type="text"
                      defaultValue={exp.inicio}
                      onChange={(e) => {
                        setExperiencia({
                          ...experiencia,
                          inicio: e.target.value,
                        });
                      }}
                    ></input>
                    <label>{isInEnglish ? "End date:" : "Fim:"}</label>
                    <input
                      type="text"
                      defaultValue={exp.fim}
                      onChange={(e) => {
                        setExperiencia({
                          ...experiencia,
                          fim: e.target.value,
                        });
                      }}
                    ></input>
                    <label>{isInEnglish ? "Position:" : "Cargo:"}</label>
                    <input
                      type="text"
                      defaultValue={exp.cargo}
                      onChange={(e) => {
                        setExperiencia({
                          ...experiencia,
                          cargo: e.target.value,
                        });
                      }}
                    ></input>
                    <label>{isInEnglish ? "Description:" : "Descrição:"}</label>
                    <textarea
                      defaultValue={exp.descricao}
                      onChange={(e) => {
                        setExperiencia({
                          ...experiencia,
                          descricao: e.target.value,
                        });
                      }}
                    ></textarea>
                    <Button type="submit">CONFIRM</Button>
                  </form>
                </FormField>
              ) : (
                <PreviewStyled>
                  <div key={exp.id} className="previewExp">
                    {!exp.emptyLine && !exp.repeatTitle && (
                      <>
                        <div>
                          <b>{isInEnglish ? "Experience:" : "Experiência:"}</b>
                          {exp.nome}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Start date:" : "Início:"}</b>
                          {exp.inicio}
                        </div>
                        <div>
                          <b>{isInEnglish ? "End date:" : "Fim:"} </b>
                          {exp.fim}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Position:" : "Cargo:"} </b>
                          {exp.cargo}
                        </div>
                        <div>
                          <b>{isInEnglish ? "Description:" : "Descrição:"} </b>
                          {exp.descricao}
                        </div>
                        <div className="buttons">
                          <button onClick={() => moveUpHandler(index)}>
                            UP
                          </button>
                          <button onClick={() => moveDownHandler(index)}>
                            DOWN
                          </button>
                          <Button onClick={() => setIsEditing(index)}>
                            EDIT
                          </Button>
                          <Button onClick={() => deleteHandler(exp.id)}>
                            DELETE
                          </Button>
                        </div>
                        <hr />
                      </>
                    )}
                    {exp.emptyLine === true && (
                      <div style={{ textAlign: "center" }}>
                        {`(${exp.emptyLines}x)--------------------------------`}
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(exp.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                    {exp.repeatTitle === true && (
                      <div style={{ textAlign: "center" }}>
                        ------------TITLE------------
                        <Button onClick={() => moveUpHandler(index)}>UP</Button>
                        <Button onClick={() => moveDownHandler(index)}>
                          DOWN
                        </Button>
                        <Button onClick={() => deleteHandler(exp.id)}>
                          DELETE
                        </Button>
                      </div>
                    )}
                  </div>
                </PreviewStyled>
              )
            )}
        </>
      )}
    </div>
  );
};

export default ShowExperiences;
