import React, { useState } from "react";

// Import Functions
import { emptyLine } from "../../utils/emptyLine";
import { repeatTitle } from "../../utils/repeatTitle";

// Components and Styles
import Header from "../Header";
import Button from "../Button";
import { FormField } from "../FormField";
import ShowExperiences from "./ShowExperiences";
import { FormFieldPreview } from "../FormFieldPreview";
import { Form, FormSectionStyle } from "../../pages/Main/styles";

const Experiencias = ({ experiencias, setExperiencias, isInEnglish }) => {
  const [fim, setFim] = useState("");
  const [nome, setNome] = useState("");
  const [cargo, setCargo] = useState("");
  const [inicio, setInicio] = useState("");
  const [descricao, setDescricao] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [emptyLines, setEmptyLines] = useState(1);

  const submitHandler = (e) => {
    e.preventDefault();
    const id = Math.floor(Math.random() * 10000) + 1;
    const novaExp = {
      id,
      nome,
      inicio,
      fim,
      cargo,
      descricao,
    };

    setExperiencias([...experiencias, novaExp]);
    setNome("");
    setInicio("");
    setFim("");
    setCargo("");
    setDescricao("");
    setEmptyLines(1);
  };

  const emptyLineHandler = (e) => {
    e.preventDefault();
    const newLine = emptyLine(emptyLines);
    setExperiencias([...experiencias, newLine]);
    setEmptyLines(1);
  };
  const repeatTitleHandler = () => {
    const newLine = repeatTitle();
    setExperiencias([...experiencias, newLine]);
  };

  const showFormHandler = () => {
    setShowForm(!showForm);
    setEmptyLines(1);
  };

  return (
    <FormSectionStyle>
      <div onClick={showFormHandler} style={{ cursor: "pointer" }}>
        <Header> {isInEnglish ? "EXPERIENCES" : "EXPERIÊNCIAS"} </Header>
      </div>
      {showForm && (
        <FormField>
          <Form onSubmit={submitHandler}>
            <label> {isInEnglish ? "Experience:" : "Experiência:"} </label>
            <input
              type="text"
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
              }}
            ></input>
            <label> {isInEnglish ? "Start date:" : "Início:"}</label>
            <input
              type="text"
              value={inicio}
              onChange={(e) => {
                setInicio(e.target.value);
              }}
            ></input>
            <label> {isInEnglish ? "End date:" : "Fim:"}</label>
            <input
              type="text"
              value={fim}
              onChange={(e) => {
                setFim(e.target.value);
              }}
            ></input>
            <br />
            <label> {isInEnglish ? "Posititon:" : "Cargo:"} </label>
            <input
              type="text"
              value={cargo}
              onChange={(e) => {
                setCargo(e.target.value);
              }}
            ></input>
            <br />
            <label>{isInEnglish ? "Description:" : "Descrição:"} </label>

            <textarea
              value={descricao}
              onChange={(e) => {
                setDescricao(e.target.value);
              }}
            ></textarea>
            <br />
            <Button type="submit">ADD</Button>
          </Form>
          <hr />
          <form onSubmit={emptyLineHandler}>
            <input
              type="number"
              min="1"
              max="30"
              value={emptyLines}
              onChange={(e) => {
                setEmptyLines(e.target.value);
              }}
              style={{ width: "40px" }}
            />
            <button type="submit">ADD EMPTY LINE</button>
          </form>
          <button onClick={repeatTitleHandler}>REPEAT TITLE</button>
        </FormField>
      )}
      <FormFieldPreview>
        <ShowExperiences
          isInEnglish={isInEnglish}
          experiencias={experiencias}
          setExperiencias={setExperiencias}
        />
      </FormFieldPreview>
    </FormSectionStyle>
  );
};

export default Experiencias;
