import styled from "styled-components";

export const MainStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  * {
    box-sizing: border-box;
    margin: 0;
    font-family: sans-serif;
  }
  .button-div {
    right: 8px;
    background-color: #04aa6d;
  }
  & > button {
    background-color: #282a35;
  }
  .button-div,
  & > button {
    bottom: 8px;

    position: fixed;
    margin: auto;
    width: 30%;
    text-align: center;
    padding-bottom: 0.8rem;
    color: white;
    border: none;
    padding: 0.4rem 2.5rem;
    border-radius: 3px;
    font-weight: bolder;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
  }
  .pdf-viewer-container {
    width: 50%;
    min-width: 400px;
    height: 90vh;
    margin-bottom: 30px;
  }
  .pdf-viewer {
    width: 100%;
    height: 100%;
  }
  .send-button:hover {
    background-color: #038153;
  }
`;

export const FormSectionStyle = styled.div`
  width: 100%;
  min-width: 360px;
`;

export const Formulario = styled.div`
  overflow: scroll;
  height: 90vh;
  width: 50%;
  padding-right: 8px;
  min-width: 400px;
`;

export const PreviewStyled = styled.div`
  height: 100%;
  width: 30%;
`;

export const ContinueButton = styled.label`
    padding: 15px 10px;
    margin: 5px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282a35;
    color: #FFFFFF;
    border-radius: 6px;
    font-weight: 600;
    width: 300px;
 
`;
export const ContinueButtonFile = styled.input`
   opacity: 0;
   position: absolute;
   z-index: -1;
`;

export const Form = styled.form``;
