import React, { useState } from "react";

// Import Functions
import { emptyLine } from "../../utils/emptyLine";
import { repeatTitle } from "../../utils/repeatTitle";

// Components and Styles
import Header from "../Header";
import Button from "../Button";
import { FormField } from "../FormField";
import { FormFieldPreview } from "../FormFieldPreview";
import { Form, FormSectionStyle } from "../../pages/Main/styles";
import ShowConsideracoes from "./showConsideracoes";

const Consideracoes = ({ consideracoes, setConsideracoes, isInEnglish }) => {
    const [descricao, setDescricao] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [emptyLines, setEmptyLines] = useState(1);

    const submitHandler = (e) => {
        e.preventDefault();
        const id = Math.floor(Math.random() * 10000) + 1;
        const novaExp = {
            id,
            descricao,
        };

        setConsideracoes([...consideracoes, novaExp]);
        setDescricao("");
        setEmptyLines(1);
    };

    const emptyLineHandler = (e) => {
        e.preventDefault();
        const newLine = emptyLine(emptyLines);
        setConsideracoes([...consideracoes, newLine]);
        setEmptyLines(1);
    };
    const repeatTitleHandler = () => {
        const newLine = repeatTitle();
        setConsideracoes([...consideracoes, newLine]);
    };

    const showFormHandler = () => {
        setShowForm(!showForm);
        setEmptyLines(1);
    };

    return (
        <FormSectionStyle>
            <div onClick={showFormHandler} style={{ cursor: "pointer" }}>
                <Header> {isInEnglish ? "PREMIERSOFT CONSIDERATIONS" : "CONSIDERAÇÕES PREMIERSOFT"} </Header>
            </div>
            {showForm && (
                <FormField>
                    <Form onSubmit={submitHandler}>
                        <label>{isInEnglish ? "Description:" : "Descrição:"} </label>

                        <textarea
                            value={descricao}
                            onChange={(e) => {
                                setDescricao(e.target.value);
                            }}
                        ></textarea>
                        <br />
                        <Button type="submit">ADD</Button>
                    </Form>
                    <hr />
                    <form onSubmit={emptyLineHandler}>
                        <input
                            type="number"
                            min="1"
                            max="30"
                            value={emptyLines}
                            onChange={(e) => {
                                setEmptyLines(e.target.value);
                            }}
                            style={{ width: "40px" }}
                        />
                        <button type="submit">ADD EMPTY LINE</button>
                    </form>
                    <button onClick={repeatTitleHandler}>REPEAT TITLE</button>
                </FormField>
            )}
            <FormFieldPreview>
                <ShowConsideracoes
                    isInEnglish={isInEnglish}
                    consideracoes={consideracoes}
                    setConsideracoes={setConsideracoes}
                />
            </FormFieldPreview>
        </FormSectionStyle>
    );
};

export default Consideracoes;
